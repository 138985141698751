export const availableEvents = [
    {
        name: 'TEDx',
        rules: [],
    },
    {
        name: 'ZF',
        rules: [],
    },
    {
        name: 'ZC',
        rules: [],
    }
];
